import React from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/core/styles';
import {PrismLight as SyntaxHighlighter} from 'react-syntax-highlighter';
import {python, bash} from 'react-syntax-highlighter/dist/esm/languages/prism';
import {
  tomorrow as Dark,
  solarizedlight as Light,
} from 'react-syntax-highlighter/dist/esm/styles/prism';
import {useDarkMode} from 'WithLayout';

SyntaxHighlighter.registerLanguage('python', python);
SyntaxHighlighter.registerLanguage('bash', bash);

const useStyles = makeStyles(theme => ({
  editor: {
    paddingLeft: `${theme.spacing(2)}px !important`,
    paddingRight: `${theme.spacing(2)}px !important`,
    borderRadius: theme.spacing(1 / 2),
  },
}));

const CodeHighlighter = ({children, className, ...rest}) => {
  const [themeMode] = useDarkMode();
  const classes = useStyles();
  const language = className ? className.replace(/language-/, '') : 'python';
  const style = themeMode === 'dark' ? Dark : Light;

  let trimmedChildren = children;
  let showLineNumbers = true;
  if (typeof children === 'string') {
    trimmedChildren = children.trim();
    showLineNumbers = children.split('\n').length > 3;
  }
  return (
    <div className={className} {...rest}>
      <SyntaxHighlighter
        language={language}
        style={style}
        className={classes.editor}
        showLineNumbers={showLineNumbers}
      >
        {trimmedChildren}
      </SyntaxHighlighter>
    </div>
  );
};

CodeHighlighter.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
  /**
   * Code
   */
  children: PropTypes.string.isRequired,
};

export default CodeHighlighter;
