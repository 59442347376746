/* eslint-disable import/prefer-default-export */
import React from 'react';
import {SEO} from './src/components/atoms';

export const wrapPageElement = incoming => {
  const {element, props} = incoming;
  return (
    element
      // <SEO>{element}</SEO>
  );
};
