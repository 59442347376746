import {Button} from 'gatsby-theme-material-ui';
import React from 'react';
import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles({
  listItemButton: {
    whiteSpace: 'nowrap',
  },
});

const LoginStateCTA = () => {
  // const {apolloLoading} = useApolloLoaderContext();
  // const {page, url, signIn} = usePageContext();
  const classes = useStyles();

  return (
    <Button variant="contained" color="primary" to="/tutorials/" className={classes.listItemButton}>
      Tutorials
    </Button>
  );
};

export default LoginStateCTA;
