import React from 'react';
import {Box, LinearProgress} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  bar: {
    height: 15,
    borderRadius: 3,
  },
}));

const ProgressBar = ({value, ...rest}) => {
  const classes = useStyles();
  return (
    <>
      <LinearProgress {...rest} className={classes.bar} variant="determinate" value={value} />
      <Box fontSize={14} fontWeight={400} mt={1}>
        {`You've completed ${value.toFixed(0)}% of the course`}
      </Box>
    </>
  );
};

export default ProgressBar;
