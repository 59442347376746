import React from 'react';
import {Box} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import PropTypes from 'prop-types';

const useStyles = makeStyles(() => ({
    formWrapper: {
        // width: '100%',
        // height: '100%',
        // maxWidth: '600px',
      },
      embed: {
        background: "transparent", 
        border: "1px solid #ccc",
        maxWidth: "600px"
      },
}));


const Form = ({formId}) => {

  let src = `https://airtable.com/embed/${formId}?backgroundColor=white`;

  const classes = useStyles();

  return (
    <Box className={classes.formWrapper}>
        {/* <script 
            src="https://static.airtable.com/js/embed/embed_snippet_v1.js"
        /> */}
        <iframe 
            class="airtable-embed airtable-dynamic-height" 
            className={classes.embed}
            src={src} 
            frameborder="0" 
            onmousewheel="" 
            width="100%"
            height="600" 
        />
    </Box>
  );
};

Form.propTypes = {
  formId: PropTypes.string.isRequired,
};

export default Form;
