import React from 'react';
import {Box} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import {Link} from 'gatsby-theme-material-ui';
import PropTypes from 'prop-types';

const useStyles = makeStyles(() => ({
  aspectWrapper: {
    height: '80vh' /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */,
    width: '100%',
    position: 'relative',
  },
  embed: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    margin: '0 auto',
    width: '100%',
    height: '100%',
  },
}));

const Exercise = ({title, contentSrc, outputOnly}) => {
  const classes = useStyles();

  let embedUrl = `https://repl.it/${contentSrc}?lite=true`;
  if (outputOnly) {
    embedUrl += `&outputonly=1`;
  }
  return (
    <Box>
      <Box>
        {outputOnly ? <Box m={0.5}>Click the green play button to run the code.</Box> : null}
        <Box m={0.5} component="span">
          Does the exercise show a 404 error message?
        </Box>
        <Link target="_blank" rel="noopener" href={process.env.GATSBY_REPLIT_TEAMS_JOIN_LINK}>
          Click here to sign in and join our Repl.it Team.
        </Link>
        &nbsp;Then, refresh this page.
      </Box>
      <Box className={classes.aspectWrapper}>
        <iframe title={title} className={classes.embed} src={embedUrl} frameBorder="0px" />
      </Box>
    </Box>
  );
};

Exercise.defaultProps = {
  outputOnly: false,
};

Exercise.propTypes = {
  contentSrc: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  outputOnly: PropTypes.bool,
};

export default Exercise;
