import React from 'react';
import PropTypes from 'prop-types';
// import 'react-lazy-load-image-component/src/effects/opacity.css';
// import 'leaflet/dist/leaflet.css';
import 'assets/css/index.sass';

export default function TopLayout({children}) {
  return <>{children}</>;
}

TopLayout.propTypes = {
  children: PropTypes.node,
};
