import React from 'react';
import {Box} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import PropTypes from 'prop-types';

const useStyles = makeStyles(() => ({
    soundcloudWrapper: {
        // paddingTop: '56.25%' /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */,
        // width: '100%',
        // position: 'relative',
      },
      embed: {
        // position: 'absolute',
        // top: 0,
        // left: 0,
        // bottom: 0,
        // right: 0,
        // margin: '0 auto',
        // width: '100%',
        // height: '100%',
      },
}));


const Soundcloud = ({playlistId, token, song, height}) => {
  var type = "playlists";
  if (song) {
      height = "166";
      type = "tracks";
  }
  let color = "1e1e21"; 
  let src = `https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/${type}/${playlistId}%3Fsecret_token%3Ds-${token}&color=%23${color}&auto_play=false&hide_related=false&show_comments=false&show_user=false&show_reposts=false&show_teaser=false`;

  const classes = useStyles();

  return (
    // <Box className={classes.soundcloudWrapper}>
        <iframe 
            className={classes.embed}
            src={src}
            width="100%" 
            height={height}
            scrolling="no" 
            frameBorder="no" 
            allow="autoplay" 
        >
        </iframe>
    // </Box>
  );
};

Soundcloud.defaultProps = {
    height: "300",
    song: false
};

Soundcloud.propTypes = {
  playlistId: PropTypes.string.isRequired,
  token: PropTypes.string.isRequired,
  song: PropTypes.bool,
  height: PropTypes.string
};

export default Soundcloud;
