import {GatsbyImage, getImage} from 'gatsby-plugin-image';
import {makeStyles, Typography} from '@material-ui/core';
import React from 'react';
import CardProduct from '../CardProduct';

const useStyles = makeStyles(theme => ({
  cardProduct: {
    display: 'flex',
    flexDirection: 'row',
    height: '100%',
    borderRadius: theme.spacing(1),
    '& .card-product__content': {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
  },
  image: {
    objectFit: 'cover',
    borderRadius: theme.spacing(0, 0, 10, 0),
    height: 200,
    width: 200,
    marginBottom: -5,
    [theme.breakpoints.down('xs')]: {
      height: 80,
      width: 80,
    },
    [theme.breakpoints.down('md')]: {
      height: 120,
      width: 120,
    },
  },
  tutorialPreview: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
}));

const CardLegacyTutorial = ({tutorial}) => {
  const classes = useStyles();

  const TutorialImageContent = props => {
    const {image, alt} = props;
    return <GatsbyImage image={getImage(image)} alt={alt} className={classes.image} />;
  };

  const TutorialContent = props => {
    const {title, subtitle} = props;
    return (
      <div className={classes.tutorialPreview}>
        <Typography variant="h6" color="textPrimary" gutterBottom>
          {title}
        </Typography>
        <Typography variant="body1" color="textSecondary">
          {subtitle}
        </Typography>
        <div style={{flexGrow: 1}} />
      </div>
    );
  };

  return (
    <CardProduct
      withShadow
      liftUp
      className={classes.cardProduct}
      mediaContent={<TutorialImageContent image={tutorial.heroImageFile} alt={tutorial.title} />}
      cardContent={<TutorialContent title={tutorial.title} subtitle={tutorial.previewText} />}
    />
  );
};

export default CardLegacyTutorial;
