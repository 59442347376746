import React from 'react';
import {Box, Grid, useMediaQuery} from '@material-ui/core';
import {useTheme} from '@material-ui/core/styles';
import {Image} from 'components/atoms';

const ImageGrid = ({src1, src2, src3, src4, src5, src6, ...rest}) => {
    const theme = useTheme();
    const isSm = useMediaQuery(theme.breakpoints.down('sm'), {
        defaultMatches: true,
    });
  return (
    <>
      <Box mt={4}>
        <Grid container spacing={isSm ? 1 : 3}>
            <Grid item xs={4}>
                <Image src={src1}/>
            </Grid>
            <Grid item xs={4}>
                <Image src={src2}/>
            </Grid>
            <Grid item xs={4}>
                <Image src={src3}/>
            </Grid>
            <Grid item xs={4}>
                <Image src={src4}/>
            </Grid>
            <Grid item xs={4}>
                <Image src={src5}/>
            </Grid>
            <Grid item xs={4}>
                <Image src={src6}/>
            </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default ImageGrid;
