import React from 'react';
import {Box} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import clsx from 'clsx';
import {makeHighlightStyle} from 'theme';

const useStyles = makeStyles(theme => {
  const highlightStyle = makeHighlightStyle(theme);
  return {
    quote: highlightStyle({
      colors: theme.palette.legacy.actionHighlight.quote,
      before: {content: `'\\e244'`}, // format_quote e244
    }),
    info: highlightStyle({
      colors: theme.palette.legacy.actionHighlight.info,
      before: {content: `'\\f0dc'`}, // privacy_tip f0dc
    }),
    action: highlightStyle({
      colors: theme.palette.legacy.actionHighlight.action,
      before: {content: `'\\e312'`}, // keyboard e312
    }),
    challenge: highlightStyle({
      colors: theme.palette.legacy.actionHighlight.challenge,
      before: {content: `'\\ea23  Challenge'`}, // emoji_events ea23
    }),
    solution: highlightStyle({
      colors: theme.palette.legacy.actionHighlight.solution,
      before: {content: `'\\e5d7  Show Solution'`}, // unfold_more e5d7
    }),
    transcript: highlightStyle({
      colors: theme.palette.legacy.actionHighlight.info,
      before: {content: `'\\e5d7  Show Transcript'`}, // unfold_more e5d7
    }),
    canOpen: {
      height: theme.spacing(4),
      overflow: 'hidden',
      padding: theme.spacing(4, 8),
      transition: 'padding 0.25s ease-in',
      '&:hover': {
        cursor: 'pointer',
      },
      '& :nth-child(1n)': {
        opacity: 0,
        transition: 'opacity 0.2s ease-in',
      },
    },
    open: {
      height: 'auto',
      padding: theme.spacing(9, 8, 6),
      transition: 'padding 0.25s ease-out',
      '&:before': {
        content: `'\\e5d6  Hide' !important`, // unfold_less e5d6
      },
      '& :nth-child(1n)': {
        opacity: 1,
        transition: 'opacity 0.3s ease-out',
      },
    },
  };
});

const TextBox = ({type, children}) => {
  const classes = useStyles();
  const canOpen = type === 'solution' || type === 'transcript';
  const [open, setOpen] = React.useState(false);

  const toggleOpen = () => {
    if (!canOpen) return;
    setOpen(!open);
  };

  return (
    <Box
      onClick={toggleOpen}
      className={clsx({
        [classes[type]]: true,
        [classes.canOpen]: canOpen,
        [classes.open]: open,
      })}
    >
      {children}
    </Box>
  );
};

export default TextBox;
