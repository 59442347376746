import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/core/styles';
import {FaLayerGroup} from '@react-icons/all-files/fa/FaLayerGroup';
import {FaSketch} from '@react-icons/all-files/fa/FaSketch';
import {FaCode} from '@react-icons/all-files/fa/FaCode';

const useStyles = makeStyles(() => ({
  extraSmall: {
    fontSize: 10,
  },
  small: {
    fontSize: 20,
  },
  medium: {
    fontSize: 30,
  },
  large: {
    fontSize: 40,
  },
}));

const iconNameToComponent = {
  'fas fa-layer-group': FaLayerGroup,
  'fab fa-sketch': FaSketch,
  'fas fa-code': FaCode,
};

/**
 * Component to display the icon
 *
 * @param {Object} props
 */
const Icon = props => {
  const {fontIconClass, size, fontIconColor, className, ...rest} = props;

  const classes = useStyles();

  const ComponentFromName = iconNameToComponent[fontIconClass] || 'no icon of that name';

  return (
    <ComponentFromName
      className={clsx('icon', fontIconClass, classes[size], className)}
      style={{color: fontIconColor}}
      {...rest}
    />
  );
};

Icon.defaultProps = {
  size: 'small',
};

Icon.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
  /**
   * The classes of the font icon
   */
  fontIconClass: PropTypes.string.isRequired,
  /**
   * Source set for the responsive images
   */
  size: PropTypes.oneOf(['extraSmall', 'small', 'medium', 'large']),
  /**
   * Color of the icon
   */
  fontIconColor: PropTypes.string,
};

export default Icon;
